<script setup>
import { useStore } from '@datastore';
const store = useStore();

store.fetchTags();
</script>

<template>
    <div>
        <b-field v-for="code in Object.keys(labels).filter((lang) => labels[lang] !== undefined)" :key="code" :label="code">
            <b-input v-model="labels[code]" placeholder="Titre"></b-input>
            <b-button icon-left="close" @click="labels[code] = undefined" />
        </b-field>
        <b-dropdown>
            <template #trigger>
                <b-button icon-left="menu-down">Ajouter une nouvelle langue</b-button>
            </template>

            <b-dropdown-item v-for="(lang, key) in langs" :key="key" :disabled="labels[lang.code] !== undefined" @click="labels[lang.code] = ''">
                {{ lang.text }}
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
import { langs } from '@/plugins/i18n';

export default {
    name: 'label-picker',
    props: {
        value: {
            type: Object,
        },
    },
    data: () => ({
        langs,
    }),
    computed: {
        labels: {
            set: function (labels) {
                this.$emit('input', labels);
            },
            get: function () {
                return this.value;
            },
        },
    },
};
</script>

<style scoped></style>
