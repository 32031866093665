'use strict';

import { defineStore } from 'pinia';

const SERVER_URL = process.env.VUE_APP_SERVER_URL;

export const useCredentialsStore = defineStore('credentials', {
    state: () => {
        return {
            gitlabToken: localStorage.getItem('backtivisda/gitlab/token'),
            serverToken: localStorage.getItem('backtivisda/server/token'),
        };
    },
    getters: {},
    actions: {
        setGitlabToken(gitlabToken) {
            this.gitlabToken = gitlabToken;
            localStorage.setItem('backtivisda/gitlab/token', gitlabToken);
        },
        setServerToken(serverToken) {
            this.serverToken = serverToken;
            localStorage.setItem('backtivisda/server/token', serverToken);
        },
        post(url, body) {
            return fetch(`${SERVER_URL}/${url}`, {
                method: 'POST',
                body: body,
                headers: { 'x-access-token': this.serverToken },
            });
        },
    },
});
